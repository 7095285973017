import axios from 'axios';
const API_URL = 'https://api.soccerstandings.online/';

class dataService {
    leagues() {
        return axios
            .get(API_URL + 'get_leagues', {
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            .then(response => {
                return response.data;
            });
    }
    allowed_list() {
        return axios
            .get(API_URL + 'get-allowed', {
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            .then(response => {
                return response.data;
            });
    }
    searchManual(body) {
        return axios
            .post(API_URL + "search_games", {
                headers: {
                    'Content-Type': 'application/json'
                },
                data: body
            })
            .then(response => {
                return response.data;
            })
    }
}

export default new dataService();