import axios from 'axios';
import { createStore } from 'vuex'
import dataService from '../services/data.service';
import { auth } from "./auth.module";

export default createStore({
  state: {
    sidebarVisible: '',
    sidebarUnfoldable: false,
    openModalMatch: false,
    allLeagues: [],
    allowedjar: [],
    searchedData: [],
    risbydate: [],
    risAVG: []
  },
  mutations: {
    toggleSidebar(state) {
      state.sidebarVisible = !state.sidebarVisible
    },
    toggleUnfoldable(state) {
      state.sidebarUnfoldable = !state.sidebarUnfoldable
    },
    updateSidebarVisible(state, payload) {
      state.sidebarVisible = payload.value
    },
    OPEN_MODAL_MATCH(state){
      state.openModalMatch = true;
    },
    CLOSE_MODAL_MATCH(state) {
      state.openModalMatch = false;
    },
    GET_LEAGUES(state) {
      dataService.leagues().then((data) => {
        state.allLeagues = data;
        state.allowedjar.length = 0;
        for (let i = 0; i < data.length; i++) {
          const element = data[i];
          state.allowedjar.push(element[1]);
        }
      })
    },
    SEND_SEARCH(state, data) {
      dataService.searchManual(data).then((response) => {
        state.searchedData = response;
      });
    },
    INSERT_FILTER(state, data) {
      axios.post('https://api.soccerstandings.online/insert_filter', data = data)
        .then(function (res) {
          if (res.data == 200) {
            alert(`${data.eventid} inserito correttamente`)
          } else if (res.data == 400) {
            alert(`${data.eventid} Esistente o non inserito`)
          }
        })
        .catch(function (error) {
          if(error){
            alert("Qualcosa è andato storto")
          }
        })
    },
    RIS_BY_DATE(state, filter) {
      axios.get('https://api.soccerstandings.online/getRisDate/' + filter)
        .then(function (res) {
          if (filter == 'AVG') {
            state.risAVG = res.data;
          } else if (filter == 'AllDraw') {
            state.risbydate = res.data;
          }
        })
        .catch(function (error) {
          if (error) {
            alert("Qualcosa è andato storto")
          }
        });
      console.log(state.risbydate);
    }
  },
  actions: {
    oepnModalMatch({ commit }){
      commit("OPEN_MODAL_MATCH")
    },
    closeModalMatch({ commit }){
      commit("CLOSE_MODAL_MATCH")
    },
    getLeagues({ commit }){
      commit('GET_LEAGUES');
    },
    sendSearch({ commit }, data){
      commit("SEND_SEARCH", data);
    },
    insert_filter({ commit }, data) {
      commit("INSERT_FILTER", data);
    },
    get_ris_by_date({ commit }, data) {
      commit('RIS_BY_DATE', data);
    }
  },
  modules: {
    auth,
  },
})
