export default [
  {
    component: 'CNavItem',
    name: 'Dashboard',
    to: '/dashboard',
    icon: 'cil-speedometer',
    badge: {
      color: 'primary',
      text: '',
    },
  },
  {
    component: 'CNavGroup',
    name: 'Impostazioni',
    icon: 'cil-puzzle',
    items: [
      {
        component: 'CNavItem',
        name: 'Gestisci Leghe',
        to: '/settings/insertleagues'
      }
    ]
  },
  {
    component: 'CNavTitle',
    name: 'Filtri',
    icon: 'cil-puzzle'
  },
  {
    component: 'CNavGroup',
    name: 'Filtri',
    to: '/filters',
    icon: 'cil-puzzle',
    items: [
      {
        component: 'CNavItem',
        name: 'Tutti i dati',
        to: '/filters/allData',
      },
      {
        component: 'CNavItem',
        name: 'Filtro manuale',
        to: '/filters/filtro-manuale'
      },
      {
        component: 'CNavItem',
        name: 'Filtro CX',
        to: '/filters/cx-filter'
      },
      {
        component: 'CNavItem',
        name: 'Filtro PX',
        to: '/filters/Px-filter'
      },
      {
        component: 'CNavItem',
        name: 'Filtro FX',
        to: '/filters/Fx-filter'
      },
      {
        component: 'CNavItem',
        name: 'Filtro U3',
        to: '/filters/u3-filter'
      },
      {
        component: 'CNavItem',
        name: 'Filtro AVG',
        to: '/filters/avg-filter'
      }
    ]
  },
]
